
.nav-wrapper {
  display: flex;
  margin: 0 auto;
  width: 98vw;
  margin: 1rem 1rem 0;
  position: absolute;
}
.nav-logo {
  min-width: 205px;
  margin: 2rem 0rem 0rem 0rem
}


.nav-filter, 
.nav-reset, 
.nav-main-menu{
  margin: auto;
  vertical-align: top
}
 
.nav-reset{
  margin: 0rem 1rem 0 0;
} 
.nav-main-menu{
  margin: 2rem 0rem 0 0rem;
}
 /******************* RIGHT COLOMN DATASCOPE/ LIGHT AND DARK MODE TOGGLE ****************/

.nav-filter-wrapper{
  position: absolute;
  margin: 5rem auto;
}

/******************* LEFT COLOMN DATASCOPE/ LIGHT AND DARK MODE TOGGLE ****************/


.left-col-wrapper {
  margin-left: 1rem;
  padding-top: 17rem; /* using padding instead of margin to capture mouse scroll */
  padding-bottom: 17rem;
  width: fit-content;
  float: left;
}

.left-col-zoom{
  width: fit-content;
  margin-top: 5rem;
}

.left-col-switch{
  width: fit-content;
  margin: 0;
  margin-top: 10rem;
}

/******************* RIGHT COLOMN DATASCOPE/ LIGHT AND DARK MODE TOGGLE ****************/

.right-col-wrapper{
  float: right;
  display: block;
  padding-top: 8rem; /* using padding instead of margin to capture mouse scroll */
  margin-right: 1rem;
  
  }
  
  .right-col-searchFilter,
  .right-col-yourFocus,
  .right-col-ranking
  .right-col-pgs
  {
  padding-top: 1rem;
  }
  .right-col-ranking{
    margin-bottom: 1rem;
  }
  .right-col-expandCountryButton{
    margin-bottom: 3rem;
  }

  .right-col-expandCountryButton {
  float: left;
  }

  .globe-pin-overlay {
    position: relative;
   /*  display: inline-block; */ /* <= shrinks container to image size */
    min-width: 110px !important; 
    max-width: 110px !important;
    min-height: 110px !important; 
    max-height: 110px !important; 
    transition: transform 150ms ease-in-out;
  }
  /* <= optional, for responsiveness */
   .globe-pin-overlay  img { 
     /* display: block; */
     max-width: 100%;
     height: auto;
  }
  
  .globe-pin-overlay svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 110px;
    height: 110px;
  }

  .hover-filter:hover {
    filter: brightness(110%) saturate(140%);
  }
  
  .alignRight{
    text-align: right !important;
  }
  .alignCenter100{
    width: 100% !important;
    text-align: center !important;
  }

  .monoSpaced {
    font-family: DroidSansMono, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;  
  }

/* Duration for stream tooltip */
.duration-1500 {
  transition-duration: 2000ms;
}

.pointer-events-changed{
  pointer-events: auto;
}

.bubbles-max-width{
  max-width: 100%;
}

@media (min-width: 1280px) {
  .pointer-events-changed{
    pointer-events: none;
  }
  .bubbles-max-width{
    max-width: 660px;
  }

}